<template>
    <div class="app-container" ref='sendRecord' @scroll="scroll">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium" filterable clearable
                               @change="handleInstitution" placeholder="请选择单位" class="form-line-item"
                               ref="selectInstitution"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                                   :value="item.institutionId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                               class="form-line-item" :disabled='batchShow'
                               ref="selectBatchId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                   :value="item.batchId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="论文题目:">
                    <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                              class="form-line-item">
                    </el-input>
                </el-form-item>
               <el-form-item label="编号:">
                   <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
                   </el-input>
               </el-form-item>
                <el-form-item label="送审规则:">
                    <el-select v-model="search.sendingRuleId" size='medium' clearable placeholder="请选择规则"
                               class="form-line-item" :disabled='sendRuleShow'
                               ref="selectSendingRuleId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in sendRuleList" :key='item.sendingRuleId'
                                   :label="item.degreeName" :value="item.sendingRuleId"></el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item label="异常:">
                    <el-select v-model="search.institutionId" size="medium" filterable clearable
                        @change="handleInstitution" placeholder="请选择异常" class="form-line-item">
                        <el-option label="无" value="0"></el-option>
                        <el-option label="同一篇论文评分分差大于**分" value="1"></el-option>
                        <el-option label="评分小于**分" value="2"></el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <el-form :inline="true" class="demo-form-inline">

                <el-form-item label="状态:">
                    <el-select v-model="search.state" size="medium" clearable placeholder="请选择审核状态"
                               class="form-line-item"
                               ref="selectState"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option label="未审核" :value="0"></el-option>
                        <el-option label="已审核" :value="1"></el-option>
                        <el-option label="需退回" :value="2"></el-option>
                        <el-option label="需作废" :value="3"></el-option>
                        <el-option label="已作废" :value="4"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="评阅结果:">
                  <el-select v-model="search.isPass" size="medium" clearable placeholder="请选择评阅结果"
                             class="form-line-item"
                             ref="selectIsPass"
                             @visible-change="isShowSelectOptions"
                             :disabled="search.isPassPaper ===''?false:true">
                    <el-option label="通过" :value="1"></el-option>
                    <el-option label="未通过" :value="0"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="论文状态:">
                  <el-select v-model="search.isPassPaper" size="medium" clearable placeholder="请选择论文状态"
                             class="form-line-item"
                             ref="selectIsPassPaper"
                             @visible-change="isShowSelectOptions"
                             :disabled="search.isPass ===''?false:true">
                    <el-option label="通过" :value="1"></el-option>
                    <el-option label="未通过" :value="0"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="返回状态:">
                    <el-select v-model="search.back" size="medium" clearable placeholder="请选择返回状态"
                               class="form-line-item"
                               ref="selectBack"
                               @visible-change="isShowSelectOptions">
                        <el-option label="未返回" :value="0"></el-option>
                        <el-option label="已返回" :value="1"></el-option>
                    </el-select>
                </el-form-item>


<!--              <el-form-item label="分差大于:">-->
<!--                <el-input placeholder="请输入分差大于的值" style="width:180px;" v-model="search.scoreDifference" size='medium'-->
<!--                          class="form-line-item">-->
<!--                </el-input>-->
<!--              </el-form-item>-->

                <!-- <el-form-item label="学位类型:">
                    <el-input placeholder="请输入学位类型" v-model="search.paperType" size='medium' class="form-line-item">
                    </el-input>
                </el-form-item> -->
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                        搜索
                    </el-button>
                    <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="mini" @click="exportReviewHandle">批量导出评阅书</el-button>
                    <!-- <el-button type="success" size="mini">批量打印评阅书</el-button> -->
                    <el-button type="success" size="mini" @click="exportTableHandle">导出评审信息表</el-button>

<!--                    <el-button type="success" size="mini" @click="exportSpecialHandle">重点关注阅书</el-button>-->
                    <!-- <el-button type="success" size="mini" @click="publishReview">发布评阅书</el-button> -->
                    <el-button type="success" size="mini" @click="reGenerateReview">重新生成评阅书</el-button>
                    <el-button type="success" size="mini" @click="reGeneratePdf">重新生成PDF</el-button>
                    <el-button type="danger" size="mini" @click="editBacked">作废</el-button>

                </template>
                <template #tools>
                  <el-button type="warning" size="mini" @click="exportSpecialHandle" style="margin-right:5px">重点关注评阅书
                  </el-button>
                    <el-button type="success" size="mini" @click="uploadCheckResult" style="margin-right:5px">上传审核结果
                    </el-button>
                    <el-button type="success" size="mini" @click="replaceCheckResult" style="margin-right:10px">替换文件
                    </el-button>
                    <!-- <el-button type="success" size="mini" @click="uploadReviewInfo" style="margin-right:10px">上传评审信息
                    </el-button> -->
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar reviewList" round align="left"
                       ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                       :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                       :data="paperList">
                <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="reviewName" title="题目-序号" min-width="300"
                            :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institution" title="单位" width="240" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="batchName" title="批次" width="240" :show-overflow="'tooltip'"></vxe-column>
               <vxe-column field="expertName" title="评阅人" width="100" :show-overflow="'tooltip'"></vxe-column>
               <vxe-column field="expertOrg" title="评阅人单位" width="150" :show-overflow="'tooltip'"></vxe-column>
               <vxe-column field="reviewContent" title="分数" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="isPass" title="评阅结果" width="100" :show-overflow="'tooltip'">
                    <template #default="{ row }">
                        <div :style="{color:row.isPass==1?'green':'#bf291b'}">
                          {{ row.isSubmit == 0 ? "" :row.isPass == 1 ? '通过' :'未通过' }}
<!--                          {{ row.isPass == 1 ? '通过' : '未通过' }}-->
                        </div>
                    </template>
                </vxe-column>
                <vxe-column field="isSubmit" title="返回状态" width="100" :show-overflow="'tooltip'" sortable>
                    <template #default="{ row }">
                        {{ row.isSubmit == 1 ? '已返回' : '未返回' }}
                    </template>
                </vxe-column>
                <vxe-column field="isCheck" title="审核状态" width="100" :show-overflow="'tooltip'" sortable>
                    <template #default="{ row }">
                        <div :style="{color:colorMap[row.isCheck]}">{{ statueMap[row.isCheck] }}</div>
                    </template>
                </vxe-column>
                <!-- <vxe-column field="isPublish" title="是否发布" width="100" sortable>
                    <template #default="{ row }">
                        {{row.isPublish==1?'是':'否'}}
                    </template>
                </vxe-column> -->
                <!-- <vxe-column field="isError" title="是否异常" width="100" sortable>
                    <template #default="{ row }">
                        <template v-if="row.paperId==753">
                            <el-popover placement="top" width="200" trigger="hover">
                                <el-tag type="danger">同一篇论文评分分差大于20分</el-tag> <br /><br />
                                <el-tag type="danger">评分小于70分</el-tag>
                                <el-button size="medium" type="text" slot="reference" style="color:red" title="查看异常"
                                    @click="errorHandle">是
                                </el-button>
                            </el-popover>
                        </template>
                        <template v-else>
                            否
                        </template>
                    </template>
                </vxe-column> -->
                <!-- <vxe-column title="操作" width="250">
                        <template #default="{ row }">
                            <el-button size="small" type="text">预览</el-button>
                            <el-button size="small" type="text">确认审核</el-button>
                            <el-button size="small" type="text" @click="updateReviewHandle">更新评阅书</el-button>
                        </template>
                    </vxe-column> -->
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                       :total="page.total" :page-sizes="page.pageSizes"
                       :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                       @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 弹窗 -->
        <vxe-modal v-model="exportReviewModel" :position="{top: '0px'}" width="600" height="450" :show-footer='true'
                   show-zoom resize :transfer='true'>
            <template #title>
                导出评阅书
            </template>
            <vxe-form :data="download" title-align="right" title-width="250">
                <!-- <vxe-form-item field="sendType" title="已选评阅书数：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <span style="color:red;">{{download.reviewNum}}</span> 份
                    </template>
                </vxe-form-item> -->

                <vxe-form-item field="sendType" title="是否包含评审人信息：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-radio-group v-model="data.isWithExpert">
                            <vxe-radio :label="1" content="是" style="width:80px;"></vxe-radio>
                            <vxe-radio :label="0" content="否" style="width:80px;"></vxe-radio>
                            <vxe-radio :label="2" content="全部" style="width:80px;"></vxe-radio>
                        </vxe-radio-group>
                    </template>
                </vxe-form-item>
                <vxe-form-item title="评阅书导出文件格式：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-radio-group v-model="data.form">
                            <vxe-radio :label="0" content="WORD" style="width:80px;"></vxe-radio>
                            <vxe-radio :label="1" content="PDF" style="width:80px;"></vxe-radio>
                            <vxe-radio :label="2" content="全部" style="width:80px;"></vxe-radio>
                        </vxe-radio-group>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="paperType" title="评阅书导出范围：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-radio-group v-model="data.range">
                            <vxe-radio :label="0" content="全部导出" style="width:80px;"></vxe-radio>
                            <vxe-radio :label="1" content="过滤导出" style="width:80px;"></vxe-radio>
                            <vxe-radio :label="2" content="条件导出" style="width:80px;"></vxe-radio>
                        </vxe-radio-group>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="gap" title="分差：" v-if="download.range == 2" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.gap" placeholder="整数类型" type="integer" style="width:100px;">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="bottom" title="低于最小值：" v-if="download.range == 2" :span="24"
                               :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.bottom" placeholder="整数类型" type="integer" style="width:100px;">
                        </vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button type="primary" size="small" @click="confirmExportReview">确认</el-button>
            </template>
        </vxe-modal>
        <!-- 导出评阅表弹窗 -->
        <vxe-modal v-model="exportTableModel" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom resize
                   :transfer='true'>
            <template #title>
                导出评审信息表
            </template>
            <vxe-form :data="download" title-align="right" title-width="200">
                <vxe-form-item title="选择导出评审表格式：" :span="12" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.format" placeholder="请选择" @change="changeHandle" size="medium"
                                    transfer clearable>
                            <vxe-option value="表1" label="表1"></vxe-option>
                            <vxe-option value="表2" label="表2"></vxe-option>
                            <vxe-option value="表3" label="表3"></vxe-option>
                            <vxe-option value="自定义" label="自定义"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <el-form class="editName">
                <el-form-item label="论文信息：" label-width="20%">
                    <el-checkbox-group v-model="formData.paperInfos">
                        <el-checkbox v-for="item in paperInfos" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="专家信息：" label-width="20%">
                    <el-checkbox-group v-model="formData.expertInfos">
                        <el-checkbox v-for="item in expertInfos" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="评阅书信息：" label-width="20%">
                    <el-checkbox-group v-model="formData.reviewInfos">
                        <el-checkbox v-for="item in reviewInfos" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmExportSave'>导出并预存</el-button>
                <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
            </template>
        </vxe-modal>
        <vxe-modal v-model="exportTableSaveModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
                   resize :transfer='true'>
            <template #title>
                保存为新评审信息表
            </template>
            <vxe-form :data="download" title-align="right" title-width="150">
                <vxe-form-item title="预存名称：" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.format" placeholder="请输入文本" type="text"></vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
            </template>
        </vxe-modal>
        <vxe-modal v-model="updataReviewModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
                   :transfer='true'>
            <template #title>
                更新评阅书
            </template>
            <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传doc文件，且不超过500kb</div>
            </el-upload>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
            </template>
        </vxe-modal>
        <!-- <vxe-modal v-model="errorModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                异常原因
            </template>
            <div style="text-align:center;">
                <el-tag type="danger">同一篇论文评分分差大于20分</el-tag> <br/><br/>
            <el-tag type="danger">评分小于70分</el-tag>
            </div>
        </vxe-modal> -->

        <!-- 上传论文 -->
        <vxe-modal v-model="uploadPaperModel" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom resize
                   :transfer='true'>
            <template #title>
                上传审核结果
            </template>
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-upload class="upload-demo" ref='paperInfo' :auto-upload='false' drag action="#">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将审核信息表拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
                    </el-upload>
                </el-col>
                <el-col :span="12">
                    <el-upload class="upload-demo" ref='paperRaw' :data="uploadData" :auto-upload='false'
                               action="/api/sending/uploadReviews" :on-success="uploadSuccess" :on-error="uploadError"
                               :on-progress="uploadProgress" drag>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将评阅书原件压缩包拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">请上传zip文件。</div>
                    </el-upload>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadPaperHandle'>确认</el-button>
            </template>
        </vxe-modal>
        <vxe-modal v-model="replaceModel" :position="{top: '0px'}" width="400" :show-footer='true' show-zoom resize
                   :transfer='true'>
            <template #title>
                上传替换文件
            </template>
            <el-row :gutter="10">

                <el-col :span="12">
                    <el-upload class="upload-demo" ref='replaceRow' :data="replaceData" :auto-upload='false'
                               action="/api/sending/replaceReviewFile" :on-success="replaceUploadSuccess"
                               :on-error="replaceUploadError"
                               :on-progress="replaceUploadProgress" drag>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将评阅书原件压缩包拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">请上传zip文件。</div>
                    </el-upload>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadReplaceHandle'>确认</el-button>
            </template>
        </vxe-modal>
        <el-dialog :title="uploadTitle" :visible.sync="uploadDiaVis" width="30%" :close-on-click-modal="false"
                   :show-close='showClose' :center='true' class="dialog">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="percent" :status="proStatus"></el-progress>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="lookCheckResult" v-if='checkSuccess'>查看检测结果</el-button>
                <el-button type="primary" size="small" @click="downloadError" v-show='!checkSuccess&&showClose'>
                    下载错误信息表</el-button>
            </span> -->
        </el-dialog>

        <vxe-modal v-model="uploadReviewInfoModal" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom
                   resize
                   :transfer='true'>
            <template #title>
                上传评审信息
            </template>
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-upload class="upload-demo" ref='uploadReviewInfo' :auto-upload='false' drag action="#">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将评审信息表拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
                    </el-upload>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadReviewInfo'>确认</el-button>
            </template>
        </vxe-modal>

        <vxe-modal v-model="exportSpecialModel" :position="{top: '0px'}" width="600" height="200" :show-footer="true"
                   show-zoom resize :transfer="true">
            <template #title>
              导出特殊评阅书
            </template>
            <vxe-form :data="download" title-align="right" title-width="250">
              <!-- 分差输入框 -->
              <vxe-form-item field="gap" title="分差大于：" :span="24" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.gap" placeholder="请输入分差" type="number" style="width:150px;"></vxe-input>
                </template>
              </vxe-form-item>
            </vxe-form>

            <!-- 确认按钮 -->
            <template #footer>
              <el-button type="primary" size="small" @click="confirmSpecialReview">确认</el-button>
            </template>
          </vxe-modal>
      </div>
</template>

<script>
// import dateFormat from '../../../public/tools/date'
import XLSX from 'xlsx'

export default {
    name: 'reviewCheck_index',
    data() {
        return {
            // 异常弹窗
            // errorModel:false,
            //数据加载
            loading: true,
            //输入条件
            input3: '',
            select: '1',
            //折叠面板显示与
            collapseShow: false,
            //搜索条件
            institutionList: [],
            batchList: [],
            sendRuleList: [],
            singleSearchLabel: 'title',
            singleSearchValue: '',
            student: '',
            search: {
                institutionId: '',
                batchId: '',
                title: '',
                oldId: '',
                paperType: '',
                // author: '',
                state: '',
                sendingRuleId: '',
                back: '',
                isPass: '',
                isPassPaper:''
                // scoreDifference:''
            },
            batchShow: true,
            sendRuleShow: true,
            //论文列表
            paperList: [{
                paperId: 1,
                title: '论文题目论文题目论文题目论文题目-1',
                institutionName: '南京理工大学',
                batchName: '2021硕士第一批',
                isCheck: 1,
                isPublish: 0
            }],
            //送审情况弹窗
            conditionModel: false,
            // 导出评阅书弹窗
            exportReviewModel: false,
            //下载对话框中的信息
            download: {
                isWithExpert: 1,
                form: 0,
                range: 0,
                reviewNum: '0',
                paperNum: '0',
                gap: 0,
                bottom: 0
            },
            // 导出评审信息表
            exportTableModel: false,
            // 导出并预存弹窗
            exportTableSaveModel: false,
            // 更新评阅书
            updataReviewModel: false,
            //评阅书列表
            receiptList: [{
                isSelected: '1',
                group: '1',
                expertName: '史唅',
                university: '南京理工大学',
                expertTitle: '正高级职称',
                expertType: '博士生导师',
                resultKeywords: '关键词1;关键词2;关键词3;关键词;',
                score: '100',
                statue: '已提交',
            },
                {
                    isSelected: '1',
                    group: '1',
                    expertName: '史唅',
                    university: '南京理工大学',
                    expertTitle: '正高级职称',
                    expertType: '博士生导师',
                    resultKeywords: '关键词1;关键词2;关键词3;关键词;',
                    score: '100',
                    statue: '已提交',
                }
            ],
            formData: {
                paperInfos: [],
                expertInfos: [],
                reviewInfos: [],
            },
            paperInfos: ['论文编号', '论文名称', '学生姓名', '学生专业', '学位类型', '导师姓名'],
            expertInfos: ['专家姓名', '专家单位', '专家职称', '专家类型', '专家邮箱', '专家手机号'],
            reviewInfos: ['论文选题', '论文综述', '独立解决实际问题的能力', '工作量', '技术难度与先进性', '新思想或新方法经济效益或社会效益', '结构严谨性', '语言通顺性',
                '论文写作水平正确性', '论文写作水平规范性', '综合意见', '总体评价', '熟悉程度'
            ],
            paperKeywords: '',
            //发送进度条
            page: {
                currentPage: 1,
                pageSize: 10,
                pageCount: 40, //自己用
                pageSizes: [10, 20, 50, 100, 200, 500, {
                    label: '全部数据',
                    value: 10000
                }],
                total: 100
            },
            // 上传审核结果弹窗
            uploadPaperModel: false,
            replaceModel: false,
            uploadData: {},
            replaceData: {},
            sendProcessVis: false,
            // showClose: false,
            sendProcessTitle: '发送中',
            percent: 0,
            uploadTitle: '',
            uploadDiaVis: false,
            showClose: false,
            proStatus: '',
            statueMap: {
                0: '未审核',
                1: '已审核',
                2: '需退回',
                3: '需作废',
                4: '已作废'
            },
            colorMap: {
                0: '#E6A23C',
                1: '#67C23A',
                2: '#F56C6C',
                3: '#F56C6C'
            },
            // 上传审核结果弹窗
            uploadReviewInfoModal: false,
            exportSpecialModel:false,
        }
    },
    mounted() {
    },
    activated() {
        // this.$nextTick(() => {
        //     console.log(this.$store.state.sendRecord.scrollTop);
        //     this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
        // })
    },
    created() {
        window.addEventListener('keydown', this.handleKeyPress);
        this.loading = false;
        //获取送审单位
        this.getInstitution();
        //获取论文列表
        this.getPaperList();
        //开启定时任务
        // this.timer();
    },
    methods: {
      handleKeyPress(event) {
        if (event.keyCode === 13) {
          this.batchSearch()
        }
      },
      // 异常弹窗
      // errorHandle(){
      //     this.errorModel = true;
      // },
      // 获取论文列表
      getPaperList() {
        this.loading = true;
        let param = new URLSearchParams();
        param.append("institutionId", this.search.institutionId);
        param.append("batchId", this.search.batchId);
        param.append("sendingRuleId", this.search.sendingRuleId);
        param.append("checkState", this.search.state);
        param.append("isPass", this.search.isPass);
        param.append("isPassPaper", this.search.isPassPaper);
        param.append("isSubmit", this.search.back);
        param.append("title", this.search.title);
        param.append("oldId", this.search.oldId);
        param.append("paperType", this.search.paperType);
        param.append("pageIndex", this.page.currentPage);
        param.append("pageSize", this.page.pageSize);
        param.append('isPublish', 0);
        param.append('countType', 0);
        // param.append("scoreDifference", this.search.scoreDifference);
        this.$api.reviewCheck.getReviews(param)
            .then(res => {
              if (res.data.code == 200) {
                this.paperList = res.data.data;
                console.log(this.paperList)
                this.page.total = res.data.count;
                this.loading = false;
              }
            }).catch(err => {
          this.$message.warning("服务器维护");
        })
      },
      // 下载zip
      getZip(path) {
        this.$api.reviewCheck.getZip({
          path: path
        }).then(res => {
          console.log(res);
          let data = res.data;
          let str = res.headers['content-disposition'].split(';')[1].split('=')[1];
          let filename = "";
          for (let i = 1; i < str.length - 1; i++) filename += str[i];
          let blob = new Blob([data], {
            type: 'application/octet-stream;charset=utf-8'
          });
          let url = window.url || window.webkitURL;
          let href = url.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = href;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
          // this.loading.close();
        }).catch(err => {
          this.$message.warning("导出格式有误");
        })
      },
      // 修改为已退回
      editBacked() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning("请选择评阅书！");
          return;
        }
        let reviews = [];
        for (let i = 0; i < selectRecords.length; i++) {
          if (selectRecords[i].isCheck != 3) {
            this.$message.warning("请选择审核状态为需退回的评阅书");
            return;
          }
        }
        selectRecords.forEach(item => {
          reviews.push(item.reviewId);
        })
        let params = {
          reviewIds: reviews.join(',')
        }
        this.$api.reviewCheck.cancelReview(params)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("修改成功");
              }
            }).catch(err => {
          this.$message.warning("修改出错");
        })
      },
      // 下载excel
      getExcel(path) {
        this.$api.reviewCheck.getTables({
          path: path
        }).then(res => {
          const blob = new Blob([res.data], {
            type: 'Application/vnd.ms-excel;charset=utf-8'
          });
          let url = window.URL || window.webkitURL;
          const href = url.createObjectURL(blob);
          let downloadElement = document.createElement('a');
          downloadElement.href = href;
          downloadElement.download = this.getFileName(path); //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
          loading.close();
        })
      },
      //导出评阅书
      exportReviewHandle() {
        //获取已选中的论文
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        this.confirmExportReview()
        // this.exportReviewModel = true;
      },
      // 重新生成评阅书
      reGenerateReview() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        let reviews = [];
        selectRecords.forEach(item => {
          reviews.push(item.reviewId);
        })
        let params = {
          reviewId: reviews
        }
        if (selectRecords.length <= 0) {
          this.$message.warning("请选择评阅书！");
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: '生成中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.reviewCheck.postRegenerateReviews(params)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("生成成功");
                loading.close();
              }
            }).catch(err => {
          this.$message.warning("生成失败");
          loading.close();
        })
      },
      reGeneratePdf() {
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        let reviews = [];
        selectRecords.forEach(item => {
          reviews.push(item.reviewId);
        })
        // let params = {
        //     reviewId: reviews
        // }
        if (selectRecords.length <= 0) {
          this.$message.warning("请选择评阅书！");
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: '生成中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.reviewCheck.postRegenerateReviews(reviews)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("生成成功");
                loading.close();
              }
            }).catch(err => {
          this.$message.warning("生成失败");
          loading.close();
        })
      }
      ,
      confirmExportReview() {
        let loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        // let mapper={};
        // let tpaperIds = [];
        // selectRecords.forEach((item) => {
        //     tpaperIds.push(item.paperId);
        // });
        // let paperIds = tpaperIds.filter((item, index) => {
        //     return tpaperIds.indexOf(item) == index;
        // });
        let reviewIds = [];
        selectRecords.forEach((item) => {
          reviewIds.push(item.reviewId);
        });
        let params = {
          // paperIds: paperIds.join(','),
          reviewIds: reviewIds.join(','),
          isWithExpert: this.download.isWithExpert,
          form: this.download.form,
          range: this.download.range,
          gap: this.download.gap,
          bottom: this.download.bottom,
          isPublish: 0
        };
        this.$api.reviewCheck.getReviewsZip(params)
            .then(res => {
              if (res.data.code == 200) {
                this.getZip(res.data.data[0]);
                console.log(1111111111111)
                // this.getExcel(res.data.data[1]);
                loading.close();
              }
            })
            .catch(err => {
              this.$message.warning("生成压缩包错误！");
            })
      },
      getFileName(str) {
        return str.substr(str.indexOf('\\') + 1)
      },
      exportTableHandle() {
        // this.exportTableModel = true;
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        ;
        let reviewIds = [];
        selectRecords.forEach((item) => {
          reviewIds.push(item.reviewId);
        });
        // console.log(reviewIds);
        // // let params = {
        // //     reviewIds: reviewIds
        // // }
        // let params = new FormData();
        // params.append("reviewIds",reviewIds);
        let loading = this.$loading({
          lock: true,
          text: '下载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$api.reviewCheck.getStatisticsTable(reviewIds)
            .then(res => {
              this.$api.reviewCheck.getTables({
                path: res.data.data
              }).then(res1 => {
                const blob = new Blob([res1.data], {
                  type: 'Application/vnd.ms-excel;charset=utf-8'
                });
                let url = window.URL || window.webkitURL;
                const href = url.createObjectURL(blob);
                let downloadElement = document.createElement('a');
                downloadElement.href = href;
                downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                loading.close();
              })

            })
            .catch(err => {
              this.$message.warning("导出评审信息表错误");
            })
      },
      cfmExport() {

      },
      changeHandle(item) {
        // if(item.value == '自定义')
        // {

        // }
      },
      cfmExportSave() {
        this.exportTableSaveModel = true;
      },
      // 发布评阅书
      publishReview() {
        let paperNum = 0;
        let reviewNum = 0;
        this.$confirm('已选 ' + paperNum + ' 篇论文，共 ' + reviewNum + ' 个评阅书', '提示', {
          confirmButtonText: '发布',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(res => {
          let loading = this.$loading({
            lock: true,
            text: '发布中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var par = {
            paperIds: this.paperIds
          };
          this.$axios.post('/sending_paper/publish_review', par).then(res => {
            loading.close();
            if (res.data.data == 1) {
              this.$message.success("发布成功！");
              this.getPaperList(this.search, this.page.currentPage, this.page
                  .pageSize);
            } else {
              this.$message.warning("发布失败！");
            }
          }).catch(err => {
            this.$message.warning('服务器维护');
          })
        })
            .catch(err => {
            })
      },
      // 上传审核结果
      uploadCheckResult() {

        this.$nextTick(() => {
          this.$refs.paperInfo.uploadFiles.length = 0;
          this.$refs.paperRaw.uploadFiles.length = 0;
        });
        this.uploadPaperModel = true;
      },
      replaceCheckResult() {
        // this.$nextTick(() => {
        //     this.$refs.replaceRow.uploadFiles.length = 0;
        // });

        this.replaceModel = true;
      },
      cfmUploadReplaceHandle() {

        if (this.$refs.replaceRow.uploadFiles.length < 1) {
          this.$message.warning("请选择上传文件信息");
          return;
        } else {
          console.log(this.$refs.replaceRow.uploadFiles)
          console.log(this.$refs.replaceRow.uploadFiles.length)
        }

        let loading = this.$loading({
          lock: true,
          text: '正在上传',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let params = new FormData();
        // console.log(this.$refs.replaceRow)
        // console.log(this.replaceData)
        params.append('file', this.$refs.replaceRow.uploadFiles[0].raw);

        // console.log(params);
        this.$api.reviewCheck.UploadReplaceInfo(params)
            .then(res => {
              console.log(res)
              if (res.data.code == 200 && res.data.msg == null) {
                this.$message.success("上传成功！");
                this.replaceModel = false;
                this.$refs.replaceRow.uploadFiles.length = 0;

                loading.close();
              } else {
                let msg = res.data.msg;
                msg = msg.split('\n');
                msg = msg.join('<br><br>')
                console.log(msg)
                this.$message.warning({
                  dangerouslyUseHTMLString: true,
                  message: `<strong>${msg}</strong>`
                });
                // this.$message.warning(msg);
                loading.close();
              }
            }).catch(err => {
          this.$message.warning("上传失败");
          loading.close();
        })
      },
      cfmUploadPaperHandle() {
        if (this.$refs.paperInfo.uploadFiles.length < 1) {
          this.$message.warning("请选择审核信息");
          return;
        }
        var xlsFile = this.$refs.paperInfo.uploadFiles[0];
        // var zipFile = this.$refs.paperRaw.uploadFiles[0];

        // const loading = this.$loading({
        //     lock: true,
        //     text: '正在上传',
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // });

        var reader = new FileReader(); //读取文件内容
        reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
        // console.log(xlsFile.raw);
        reader.onload = (e) => {
          // console.log(123);
          var dd = e.target.result;
          var workbook = XLSX.read(dd, {
            type: 'buffer'
          });
          var sheetname = workbook.SheetNames[0]
          var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
          var paperDetails = [];
          for (let i in tables) {
            let row = tables[i];
            // for (let i in excelTitle) {
            //     if (typeof (row[excelTitle[i]]) == 'undefined') {
            //         this.$message.warning(excelTitle[i] + '不可为空');
            //         return;
            //     }
            // }

            // if (row['学位类型（必填）'] != this.auditInfo.paperType) {
            //     this.$message.warning("上传的论文信息表与所选批次中送审论文类型不一致");
            //     return;
            // }
            // var paps = {
            //     // "oldId": row['论文编号（必填，与文件名保持一致）'],
            //     "reviewId": typeof (row["评阅书ID"]) == 'undefined' ? '' : row[
            //         "评阅书ID"],
            //     "checkState": typeof (row["审核状态"]) == 'undefined' ? '' : row[
            //         '审核状态'],
            // };

            paperDetails.push(row);
          }
          ;
          // this.uploadData.institutionId = this.institutionId;
          // this.uploadData.batchId = this.batchId;

          if (this.$refs.paperRaw.uploadFiles.length == 0) {
            // let params = {
            //     'checkTableDetails':JSON.stringify(paperDetails)
            // }
            const loading = this.$loading({
              lock: true,
              text: '正在上传',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let params = new FormData();
            params.append('checkTableDetails', JSON.stringify(paperDetails));
            params.append('file', null);

            console.log(params);
            this.$api.reviewCheck.UploadCheckInfo(params)
                .then(res => {
                  console.log(res)
                  // console.log(1)
                  if (res.data.code == 200) {
                    this.$message.success("上传成功！");
                    this.uploadPaperModel = false;
                    this.$refs.paperInfo.uploadFiles.length = 0;
                    this.$refs.paperRaw.uploadFiles.length = 0;
                    loading.close();
                  } else if (res.data.code == 201) {
                    this.$message.warning("警告！");
                    this.uploadPaperModel = false;
                    this.$refs.paperInfo.uploadFiles.length = 0;
                    this.$refs.paperRaw.uploadFiles.length = 0;
                    loading.close();
                  } else {
                    this.$message.warning(res.data.msg);
                    loading.close();
                  }
                }).catch(err => {
              this.$message.warning("上传失败");
              loading.close();
            })
          } else {
            this.uploadData.checkTableDetails = JSON.stringify(paperDetails);
            this.$refs.paperRaw.submit();
            this.percent = 0;
            this.showClose = false;
            this.uploadTitle = '正在上传';
            this.proStatus = '';
            this.checkSuccess = false;
            this.uploadDiaVis = true;
          }
        }
      },
      uploadReviewInfo() {
        this.uploadReviewInfoModal = true;
      },
      cfmUploadReviewInfo() {
        if (this.$refs.uploadReviewInfo.uploadFiles.length < 1) {
          this.$message.warning("请选择评审信息");
          return;
        }
        var xlsFile = this.$refs.uploadReviewInfo.uploadFiles[0];

        const loading = this.$loading({
          lock: true,
          text: '正在上传',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        var reader = new FileReader(); //读取文件内容
        reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
        reader.onload = (e) => {
          console.log(this.batchId);
          var dd = e.target.result;
          var workbook = XLSX.read(dd, {
            type: 'buffer'
          });
          var sheetname = workbook.SheetNames[0]
          var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
          // for (let i in tables) {
          //     let row = tables[i];
          //     var paps = {
          //         // "oldId": row['论文编号（必填，与文件名保持一致）'],
          //         "reviewId": typeof (row["评阅书ID"]) == 'undefined' ? '' : row[
          //             "评阅书ID"],
          //         "isBack": typeof (row["是否返回给院校"]) == 'undefined' ? '' : row[
          //             '是否返回给院校'],
          //     };
          //     paperDetails.push(paps);
          // };
          var paperDetails = tables.map(item => {
            return {
              reviewId: item.reviewId,
              content: JSON.stringify(item)
            }
          })
          // console.log(paperDetails)
          // let params = new FormData();
          // params.append('backTableDetails',JSON.stringify(paperDetails))
          // let params = {
          //     backTableDetails:JSON.stringify(paperDetails)
          // }
          this.$api.reviewCheck.uploadExcelData(paperDetails)
              .then(res => {
                this.$message.success("上传成功！");
                loading.close();
                this.uploadReviewInfoModal = false;
              })
              .catch();

        }
      },
      uploadProgress(event, file, fileList) {
        this.percent = parseInt(event.percent);
      },
      replaceUploadProgress(event, file, fileList) {
        this.percent = parseInt(event.percent);
      },
      uploadError(err, file, fileList) {
        this.$message.warning("服务器维护！");
        this.showClose = true;
        this.percent = 99;
        this.uploadTitle = '上传失败';
        this.proStatus = 'exception';
        this.$refs.paperRaw.clearFiles();
        this.$refs.paperInfo.clearFiles();
      },
      replaceUploadError(err, file, fileList) {
        this.$message.warning("服务器维护！");
        this.showClose = true;
        this.percent = 99;
        this.uploadTitle = '上传失败';
        this.proStatus = 'exception';
        this.$refs.replaceRow.clearFiles();

      },
      uploadSuccess(res, file, fileList) {
        console.log(res)
        this.showClose = true;
        if (res.code == '200') {
          console.log('成功')
          this.$message.success("上传成功");
          this.uploadTitle = '上传成功';
          this.proStatus = 'success';
          this.$refs.paperInfo.uploadFiles.length = 0;
          this.$refs.paperRaw.uploadFiles.length = 0;
          // this.getOriginalPaperPreviews();
        } else if (res.code == '201') {

          let msg = res.msg;
          console.log(msg)
          this.$message.warning({
            dangerouslyUseHTMLString: true,
            message: `<strong>${msg}</strong>`
          });
          this.uploadTitle = '上传成功';
          this.proStatus = 'success';
          this.$refs.paperInfo.uploadFiles.length = 0;
          this.$refs.paperRaw.uploadFiles.length = 0;
          // this.getOriginalPaperPreviews();
        } else {
          console.log('失败')
          this.uploadTitle = '上传失败';
          this.errorUrl = res.data;
          this.proStatus = 'exception';
          this.percent = 99;
        }
        this.$refs.paperRaw.clearFiles();
        this.$refs.paperInfo.clearFiles();
      },
      replaceUploadSuccess(res, file, fileList) {
        this.showClose = true;
        if (res.code == '200') {
          this.$message.success("上传成功");
          this.uploadTitle = '上传成功';
          this.proStatus = 'success';
          this.$refs.replaceRow.uploadFiles.length = 0;


        } else {
          this.uploadTitle = '上传失败';
          this.errorUrl = res.data;
          this.proStatus = 'exception';
          this.percent = 99;
        }
        this.$refs.replaceRow.clearFiles();

      },
      updateReviewHandle() {
        this.updataReviewModel = true;
      },
      //表格刷新
      refresh() {
        this.getPaperList();
      },
      //获取送审单位
      getInstitution() {
        // let params = {
        //     pageIndex: 1,
        //     pageSize: 20
        // }
        this.$api.schoolCustom.getSendingInstitution()
            .then(res => {
              this.institutionList = res.data.data;
            })
            .catch(err => {
              this.$message.warning('接口错误');
            })
      },
      //获取送审单位的批次
      getBatch(institutionId) {
        let param = new URLSearchParams();
        param.append("institutionId", institutionId)
        this.$api.sendingP2p.getBatch(param)
            .then(res => {
              console.log(res.data.data);
              this.batchList = res.data.data;
            })
            .catch(err => {
              this.$message.warning("服务器维护");
            });
      },

      // 获取送审规则
      getSendingRules(institutionId) {
        let param = {
          institutionId: institutionId
        };
        this.$api.reviewCheck.getSendingRules(param)
            .then(res => {
              this.sendRuleList = res.data.data;
            }).catch(err => {
          this.$message.warning("获取送审规则错误");
        })
      },
      //机构选择改变
      handleInstitution(val) {
        this.search.batchId = '';
        this.search.sendingRuleId = '';
        if (val == '') {
          this.batchShow = true;
          this.sendRuleShow = true;
          return;
        }
        this.batchShow = false;
        this.sendRuleShow = false;
        this.getBatch(val);
        this.getSendingRules(val);
      },
      //定时器获取论文列表
      getTimePaperList(search, pageIndex, pageSize) {
        let param = new URLSearchParams();
        param.append("institutionId", search.institutionId);
        param.append("batchId", search.batchId);
        param.append("statue", search.statue);
        param.append("title", search.title);
        param.append("oldId", search.oldId);
        param.append("student", search.student);
        // param.append("startTime", dateFormat.dateFormat(search.time[0]));
        // param.append("endTime", dateFormat.dateFormat(search.time[1]));
        param.append("pageIndex", pageIndex);
        param.append("pageSize", pageSize);
        this.$axios.get("/sending_paper/get_condition", {
          params: param
        }).then(res => {
          console.log(res.data);
          this.paperList = res.data.data;
          this.page.total = res.data.count;
        }).catch(err => {
          this.$message.warning("服务器维护！");
        });
      },
      batchSearch() {
        this.getPaperList();
      },
      //查看单篇论文的送审情况
      handleSingleCondition(paperId, batchId, paperName) {
        this.conditionModel = true;
        this.$store.dispatch('changePaperId', paperId);
        this.$store.dispatch("changeBatchId", batchId);
        this.$store.dispatch("changePaperName", paperName);
        // this.$router.push({
        //     name: 'checkSendRecord'
        // });
      },
      //发送评阅书
      sendEmailHandle(paperId) {
        //获取已选中的论文
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        // this.process = 0;
        // this.sendProcessVis = true;
        const loading = this.$loading({
          lock: true,
          text: '正在发送',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let paperIds = [];
        selectRecords.forEach((item) => {
          paperIds.push(item.paperId);
        });
        let param = {
          paperIds: paperIds.join(',')
        };

        this.$axios.post("/sending_paper/send_email", param)
            .then(res => {
              // clearInteral(this.sendTimer);
              loading.close();
              this.showClose = true;
              if (res.data.code == 1) {
                this.process = 100;
                this.sendProcessTitle = '发送成功';
                this.$message.success("发送成功");
              } else {
                this.process = 99;
                this.sendProcessTitle = '发送失败';
                this.$message.warning("发送失败");
              }
            })
            .catch(err => {
              loading.close();
              this.$message.warning("服务器维护！");
            });
      },
      //获取发送精度
      getProcess() {
        this.$axios.get('sending_paper/progress')
            .then(res => {
              this.process = res.data.data;
            }).catch(err => {
          this.$message.warning("服务器维护");
        })
      },
      // batchSearch() {
      //     console.log(this.search);
      //     this.page.currentPage = 1;
      //     this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
      //     this.collapseShow = !this.collapseShow;
      // },
      resetHandle() {
        this.search = {
          institutionId: '',
          batchId: '',
          title: '',
          oldId: '',
          student: '',
          state: '',
          isBack: '',
          sendingRuleId: '',
          isPass: '',
          isPassPaper: ''
          // scoreDifference: ''
        };
      },
      //获取评阅书
      handleLookReview(paperId) {
        this.receiptDiaVis = true;
      },
      //查看评阅书
      handleLook(url) {
        window.open(url);
      },
      //下载评阅书
      handleDownloadReview() {

      },
      //分页操作
      handlePageChange({
                         currentPage,
                         pageSize
                       }) {
        console.log(currentPage, pageSize);
        this.page.currentPage = currentPage;
        this.page.pageSize = pageSize;
        this.getPaperList();
      },
      //每页多少数据改变
      handleChangePageSize(val) {
        this.page.pageSize = val;
        this.page.currentPage = 1;
        this.getPaperList();
      },
      firstPage() {
        this.page.currentPage = 1;
        this.getPaperList();
      },
      endPage() {
        this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
            .pageSize == 0 ? 0 : 1);
        this.getPaperList();
      },
      headerCellClassName({
                            column,
                            columnIndex
                          }) {
        return 'vxe-table-thead'
      },
      headerCellStyle({
                        row,
                        column,
                        rowIndex,
                        columnIndex
                      }) {
        return "background-color:#e0e0e0f1"
      },
      scroll(e) {
        console.log('scroll', e.target.scrollTop)
      },
      // 是否显示下拉框
      isShowSelectOptions(isShowSelectOptions) {
        if (!isShowSelectOptions) this.$refs.selectInstitution.blur();
        if (!isShowSelectOptions) this.$refs.selectBatchId.blur();

        if (!isShowSelectOptions) this.$refs.selectSendingRuleId.blur();
        if (!isShowSelectOptions) this.$refs.selectState.blur();
        if (!isShowSelectOptions) this.$refs.selectBack.blur();

      },
      //导出特殊评阅书
      exportSpecialHandle() {
        //获取已选中的论文
        let selectRecords = this.$refs.paperTable.getCheckboxRecords();
        if (selectRecords.length <= 0) {
          this.$message.warning('请选择论文！');
          return;
        }
        this.download.gap = ''; // 重置分差值
        this.exportSpecialModel = true;
      },

        confirmSpecialReview() {
          if (!this.download.gap) {
            this.$message.warning('请输入分差！');
            return;
          }
          let loading = this.$loading({
            lock: true,
            text: '下载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          // 获取已选中的论文
          let selectRecords = this.$refs.paperTable.getCheckboxRecords();

          let reviewIds = [];
          selectRecords.forEach((item) => {
            reviewIds.push(item.reviewId);
          });

          let params = {
            reviewIds: reviewIds.join(','),
            isWithExpert: this.download.isWithExpert,
            form: this.download.form,
            range: this.download.range,
            gap: this.download.gap,
            bottom: this.download.bottom,
            isPublish: 0
          };

          // 生成特殊评阅书的压缩包
          this.$api.reviewCheck.getSpecialReviewsZip(params)
              .then(res => {
                if (res.data.code === 200) {
                  this.getZip(res.data.data[0]); // 下载评阅书
                  console.log("评阅书导出成功");
                }else {
                  this.$message.warning(res.data.msg || '没有符合条件的评阅书！');
                }

                // 导出评审信息表
                this.exportSpecialTableHandle(reviewIds, params.gap); // 调用导出评审信息表的功能
                loading.close();
              })
              .catch(err => {
                this.$message.warning("生成压缩包错误！");
                loading.close();
              });

          },



      exportSpecialTableHandle(reviewIds, gap) {
        let loading = this.$loading({
          lock: true,
          text: '下载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        let params = {
          reviewIds:reviewIds,
          gap:gap
        };

        this.$api.reviewCheck.getSpecialStatisticsTable(params)
            .then(res => {
              this.$api.reviewCheck.getTables({
                path: res.data.data
              }).then(res1 => {
                const blob = new Blob([res1.data], {
                  type: 'Application/vnd.ms-excel;charset=utf-8'
                });
                let url = window.URL || window.webkitURL;
                const href = url.createObjectURL(blob);
                let downloadElement = document.createElement('a');
                downloadElement.href = href;
                downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                loading.close();
              })

            })
            .catch(err => {
              this.$message.warning("导出评审信息表错误");
            })
      },


    },
    destroyed() {
        clearInterval(this.timer);
    },
    //在页面离开时记录滚动位置
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
        next()
    },
    components: {}
}
</script>

<style scoped>
.inputSelect {
    width: 120px;
}

.collapseInput {
    margin-top: 20px;
}

.searchBtn {
    padding: 20px 0px 0px 0px;
    text-align: center;
}

.box-card {
    padding-bottom: 0px !important;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.form-line-item {
    width: 15em;
}

.el-form-item {
    margin-bottom: 10px !important;
}
</style>
<style>
.vxe-radio .vxe-radio--label {
    display: inline;
}

.reviewList .vxe-table--body-wrapper {
    height: calc(100vh - 336px);
}

.dialog .el-dialog {
    margin-top: 5vh !important;
    border-radius: 15px;
}

.searchForm .el-form-item__label {
    background-color: #F5F7FA;
    color: #909399;
}
</style>